import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import throttle from 'lodash.throttle';

import Logo from './Logo';
import TopBar from './TopBar';
import MainMenu from './MainMenu';
import Basket from './basket';
import { PAYMENT_MODE_TEST, PAYMENT_MODE } from '../../utils/payments';

import s from './index.module.scss';

const getScrollPosition = () => {
  if (typeof window === 'undefined') {
    return 0;
  }
  return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
};

export const useScroll = (timeout = 250) => {
  const defaultScrollTop = useMemo(() => getScrollPosition(), []);
  const previousScrollTopRef = useRef(defaultScrollTop);
  const [currentScrollTop, setCurrentScrollTop] = useState(defaultScrollTop);
  const [currentDirection, setCurrentDirection] = useState('up');

  useEffect(() => {
    const handleDocumentScroll = () => {
      const scrollTop = getScrollPosition();
      if (currentScrollTop - scrollTop > 2 || currentScrollTop - scrollTop < -2) {
        if (scrollTop - previousScrollTopRef.current < 0) {
          setCurrentDirection('up');
        } else if (scrollTop - previousScrollTopRef.current > 0 && scrollTop > 34) {
          setCurrentDirection('down');
        }
        setCurrentScrollTop(scrollTop);
        previousScrollTopRef.current = scrollTop;
      }
    };

    const handleDocumentScrollThrottled = throttle(handleDocumentScroll, timeout);
    window.addEventListener('scroll', handleDocumentScrollThrottled);

    return () => {
      window.removeEventListener('scroll', handleDocumentScrollThrottled);
    };
  }, [timeout]);

  return {
    scrollTop: currentScrollTop,
    previousScrollTop: previousScrollTopRef.current,
    time: timeout,
    direction: currentDirection,
  };
};

const GlobalHeader = ({ siteContentSettings }) => {
  const [isMenuCollapsed, setMenuCollapsed] = useState(true);
  const router = useRouter();
  const { direction } = useScroll();

  // Close mobile menu on any route change.
  useEffect(() => {
    if (!isMenuCollapsed) {
      setMenuCollapsed(true);
    }
  }, [router]);

  const mobileMenuToggle = () => {
    setMenuCollapsed(!isMenuCollapsed);
  };
  return (
    <header
      className={`navbar navbar-sticky ${!siteContentSettings.header.leftText && !siteContentSettings.header.rightText ? 'without-topbar' : ''}`}
      style={{
        transform:
          (siteContentSettings.header.leftText || siteContentSettings.header.rightText) &&
          direction === 'down'
            ? 'translateY(-34px)'
            : 'none',
      }}
    >
      {(siteContentSettings.header.leftText || siteContentSettings.header.rightText) && (
        <TopBar
          headerLeftText={siteContentSettings.header.leftText}
          headerRightText={siteContentSettings.header.rightText}
        />
      )}
      <div className="container header-container">
        {/* Visible only on the mobile */}
        <div className="mobile-menu-wrapper">
          <button
            className={`mobile-menu-toggle${isMenuCollapsed ? '' : ' active'}`}
            onClick={mobileMenuToggle}
          >
            <i className="material-icons menu" />
          </button>
        </div>

        <Logo />
        {/* TODO: Move the region switcher to the footer as per design in the #187099667*/}
        {/*<RegionSwitcher {...siteContentSettings.regionSettings} />*/}

        <MainMenu
          isMenuCollapsed={isMenuCollapsed}
          onMenuClick={mobileMenuToggle}
          menu={siteContentSettings.header.menu}
          regionSwitcherSettings={siteContentSettings.regionSettings}
        />

        <Basket />
      </div>
      {PAYMENT_MODE === PAYMENT_MODE_TEST && (
        <div className={s['test-payment-mode-warning']}>
          <a href="https://cw2.readme.io/docs/payments" target="_blank" rel="noopener noreferrer">
            TEST MODE
          </a>
        </div>
      )}
    </header>
  );
};

GlobalHeader.propTypes = {
  siteContentSettings: PropTypes.object.isRequired,
};

export default GlobalHeader;
